<template>
  <div class="page">
    <div class="page-header">
      <div class="page-menu">

      </div>
    </div>
    <div class="page-container">

      <img src="assets/qrcode.png" width="33%"/>
      <div class="content">
        <div class="logo"><span class="logo-lite">Web</span>Nurse</div>
        <h1>Дизайн курсов на платформе GetCourse</h1>
        <p>Отвечаем в телеграм</p>
      </div>
    </div>
    <div class="page-footer">

    </div>
  </div>
</template>


<style>
.page {
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 1120px;
  max-width: 90vw;
  padding: 48px 64px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.9);
}

.page-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.logo {
  font-size: 32px;
  font-weight: 600;
  color: #f265b1;
  text-transform: uppercase;
}

.logo-lite {
  color: #0d6279;
  font-weight: 100;
}
</style>
